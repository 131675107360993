import {track} from './segmentHelper'
const page_loaded = new Event("page_loaded");
const page_scroll = new Event("page_scroll");
const page_ended = new Event("page_ended");

let startTime;
let scrollStartTime;
let pageEndTime;
 
export function addEventListner(){
  if(window !== "undefined"){
    document.addEventListener("page_loaded", () => {
      startTime=Date.now();
      const start_time=startTime
      track('page_loaded',{start_time});
  });
document.addEventListener("page_scroll", () => {
      scrollStartTime=Date.now();
      const scroll_start_time=scrollStartTime-startTime;
      track('page_scroll',{scroll_start_time});
  });
document.addEventListener("page_ended", () => {
      pageEndTime=Date.now()
      const end_time=pageEndTime-scrollStartTime;
      track('page_ended',{end_time});
  });
  }
   
  }

export function onClientEntry  () {
  if(window !== "undefined"){
    document.dispatchEvent(page_loaded);
    let first=0
    document.addEventListener('scroll', ()=>{
      
      if (first===0){
        document.dispatchEvent(page_scroll);
        first=1;
      }
      const scrolled_height=window.scrollY
      const total_height=document.documentElement.scrollHeight-window.screen.height; 

      if (Math.trunc(scrolled_height)===total_height){
        document.dispatchEvent(page_ended);
      }
    }
    )
  }
}