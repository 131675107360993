import React from 'react';
import { Sticky, StickyProvider } from 'react-stickup';
import { Link } from 'gatsby';
import useWindowSize from '../hooks/useWindowSize';
import { Helmet } from 'react-helmet';
import logo from '../images/powerplay-logo.svg';
import '../styles/styles.scss';
import { SEGMENT_TOKEN } from '../constants'
const Header = () => {
  const { width: screenWidth } = useWindowSize();

  // styles
  const stickyDesignStyle = {
    display: 'flex',
    marginTop: '0px',
    width: '100%',
    height: '64px',
    paddingBottom: '0.8em',
    marginBottom: '0.6em',
    backgroundColor: '#ffffff',
    transition: 'all 0.1s linear',
    position: 'fixed',
    zIndex: '2000',
  };

  const powerplayLogoStyle = {
    width: 'auto',
    height: screenWidth > 990 ? '40px' : '30px',
    marginLeft: screenWidth > 990 ? '6em' : '1em',
    marginTop: '0.8em',
  };

  // jsx
  return (
    <div>
      <Helmet>
        <title>
          Powerplay | No. 1 Construction Management Software and App
        </title>
        <link rel='icon' href='/favicon.svg' type='image/png'></link>
        <script async>
          {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
            analytics.load("${SEGMENT_TOKEN}");
            }}();`}
        </script>
      </Helmet>
      <StickyProvider>
        <Sticky style={stickyDesignStyle}>
          <Link to='/'>
            <img src={logo} alt='powerplay logo' style={powerplayLogoStyle} />
          </Link>
          <Link to='/requestdemo'>
            <button className='request-demo-nav'>Request a Demo</button>
          </Link>
        </Sticky>
      </StickyProvider>
    </div>
  );
};

export default Header;
