import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import People from "./People";

const HeadText = styled(Typography)(({ theme }) => ({
  color: "#989EA4",
  fontSize: "1.125rem",
  fontWeight: "bold",
  transition: "all .8s ease-in-out",
}));
const SubText = styled(Typography)(({ theme }) => ({
  color: "#989EA4",
  fontSize: "1.125rem",
  transition: "all .8s ease-in-out",
}));

const MyContainer = styled(Container)(({ theme }) => ({
  padding: "10px",
  cursor: "default",
  transition: "all .8s ease-in-out",
}));

const onActive = {
  backgroundColor: "white",
  color: "black",
  borderLeft: "5px solid #1946BB",
};

const FourthCard = () => {
  const [isFounder, setIsFounder] = useState(false);
  const [isContractor, setIsContractor] = useState(false);
  const [isCrew, setIsCrew] = useState(true);
  const [activeCard, setActiveCard] = useState(0);

  const cards = [useRef(), useRef(), useRef()];

  const setActiveImage = (cardIdx) => {
    switch (cardIdx) {
      case 0:
        setIsFounder(false);
        setIsContractor(false);
        setIsCrew(true);
        break;

      case 1:
        setIsFounder(false);
        setIsContractor(true);
        setIsCrew(false);
        break;

      case 2:
        setIsFounder(true);
        setIsContractor(false);
        setIsCrew(false);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevActiveCard) => {
        const currentActiveCard = (prevActiveCard + 1) % 3;
        setActiveImage(currentActiveCard);
        return currentActiveCard;
      });
    }, 3000);

    cards.forEach((card, idx) => {
      card.current.addEventListener("click", () => {
        clearInterval(interval);
        setActiveCard(idx);
        setActiveImage(idx);
      });
    });

    return () => clearInterval(interval);
  }, [cards]);

  return (
    <div
      style={{
        backgroundColor: "#EEF2F9",
        width: "100%",
        padding: "5rem 0",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid
          data-sal="slide-right"
          data-sal-duration="1500"
          item
          xs={10}
          sm={8}
          md={5}
          lg={4}
        >
          <Stack direction="column" spacing={1} sx={{ margin: "auto" }}>
            <Typography fontSize="3.5rem" color="#1946BB" fontWeight="600">
              Solutions Built for You
            </Typography>
            <Stack direction="column" spacing={2}>
              <MyContainer
                ref={cards[0]}
                style={
                  activeCard === 0
                    ? onActive
                    : { borderLeft: "5px solid #EEF2F9" }
                }
              >
                <HeadText
                  id="head"
                  style={activeCard === 0 ? { color: "#3D3D3D" } : {}}
                >
                  Construction Managers
                </HeadText>
                <SubText
                  id="subhead"
                  style={activeCard === 0 ? { color: "#2E3B48" } : {}}
                >
                  Increase your management productivity by keeping the project
                  owner always in loop
                </SubText>
              </MyContainer>
              <MyContainer
                ref={cards[1]}
                style={
                  activeCard === 1
                    ? onActive
                    : { borderLeft: "5px solid #EEF2F9" }
                }
              >
                <HeadText
                  id="head"
                  style={activeCard === 1 ? { color: "#3D3D3D" } : {}}
                >
                  General Contractors
                </HeadText>
                <SubText
                  id="subhead"
                  style={activeCard === 1 ? { color: "#2E3B48" } : {}}
                >
                  Hold stakeholders accountable and know exactly why the project
                  is not on track
                </SubText>
              </MyContainer>
              <MyContainer
                ref={cards[2]}
                style={
                  activeCard === 2
                    ? onActive
                    : { borderLeft: "5px solid #EEF2F9" }
                }
              >
                <HeadText
                  id="head"
                  style={activeCard === 2 ? { color: "#3D3D3D" } : {}}
                >
                  Project Owners
                </HeadText>
                <SubText
                  id="subhead"
                  style={activeCard === 2 ? { color: "#2E3B48" } : {}}
                >
                  Scale your business with better visibility and hassle-free
                  management
                </SubText>
              </MyContainer>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          data-sal="slide-left"
          data-sal-duration="1500"
          item
          xs={10}
          sm={8}
          md={6}
          lg={5}
        >

            <People
              isFounder={isFounder}
              isContractor={isContractor}
              isCrew={isCrew}
            />

        </Grid>
      </Grid>
    </div>
  );
};

export default FourthCard;
